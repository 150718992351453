<template>
    <v-breadcrumbs
        :items="breadCrumbs"
        max-width="90%"
    >
    </v-breadcrumbs>
</template>

<script>
export default {
    computed: {
        breadCrumbs() {
            if (typeof this.$route.meta.breadCrumb === "function") {
                return this.$route.meta.breadCrumb.call(this, this.$route);
            }
            return this.$route.meta.breadCrumb;
        },
    },
};
</script>

<style>
</style>
