import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import store from './store';
import Vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import { FontAwesomeIcon } from './plugins/font-awesome';


loadFonts()

createApp(App)
    .use(router)
    .use(store)
    .use(Vuetify)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount('#app');

