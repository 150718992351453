import axios from 'axios';
import Config from '../config.json';

const API_URL = Config.VUE_APP_API_URL;// ? Config.VUE_APP_API_URL : process.env.VUE_APP_API_URL;//"https://notes-api.familie-mattar.de/"; //process.env.VUE_APP_API_URL;

class AuthService {

    parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);

        }).join(''));

        return JSON.parse(jsonPayload);

    }

    login(user) {
        var bodyFormData = new FormData();
        bodyFormData.append('username', user.username);
        bodyFormData.append('password', user.password);
        return axios({
            method: "post",
            url: API_URL + "token",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(response => {
                if (response.data.access_token) {
                    var data = response.data;
                    localStorage.setItem('user', JSON.stringify(data));
                }

                return response.data;

            });
    }

    logout() {
        localStorage.removeItem('user');
    }

}

export default new AuthService();
