<template>


        <v-app>
            <v-app-bar
                app
            >
                <template v-slot:prepend>
                    <v-app-bar-nav-icon
                        v-if="currentUser"
                        variant="text" @click.stop="drawer = !drawer"
                    >
                    </v-app-bar-nav-icon>
                </template>

                <v-app-bar-title @click="$router.push('/')" style="cursor:pointer">notes</v-app-bar-title>


                <v-spacer></v-spacer>

                <div v-if="!currentUser" class="navbar-nav ml-auto">
                    <v-btn>
                        <router-link to="/login" class="nav-link">
                            <font-awesome-icon icon="sign-in-alt" />
                        </router-link>
                    </v-btn>
                </div>

                <div v-if="currentUser" class="navbar-nav ml-auto">

                    <v-btn>
                        <a class="nav-link" @click.prevent="logOut">
                            <font-awesome-icon icon="sign-out-alt" />
                        </a>
                    </v-btn>
                </div>


            </v-app-bar>

            <v-navigation-drawer
                v-model="drawer"
                bottom
                temporary
            >
                <v-list
                    :items="items"
                >
                    <v-list-item
                        v-for="item in items"
                        v-bind:key="item.value"
                        :title="item.title"
                        :value="item.value"
                        :to="item.route"
                    ></v-list-item>
                </v-list>
            </v-navigation-drawer>

            <v-main>
                <v-container v-if="currentUser && !$route.meta.hideNav">
                    <BreadCrumb />
                </v-container>

                <router-view />
            </v-main>
        </v-app>


</template>

<script>
import BreadCrumb from "./components/BreadCrumb.vue";
export default {
    components: {BreadCrumb},
    data: () => ({
        drawer: false,
        group: null,
        items: [
            {
                title: 'Home',
                value: '/home',
                route: '/home',
            },
            {
                title: 'Starred',
                value: '/starred',
                route: '/starred',
            },
            {
                title: 'Profile',
                value: '/profile',
                route: '/profile',
            },
        ],
    }),

    watch: {
        group () {
            this.drawer = false;
        },
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    methods: {
        logOut() {
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');

        }

    }

    };
</script>
