import { createWebHistory, createRouter } from "vue-router";
import Config from "./config.json";


// lazy-loaded
const Login = () => import("./components/LoginPage.vue");
const Profile = () => import("./components/ProfilePage.vue");
const Starred = () => import("./components/StarredPage.vue");
const Highlights = () => import("./components/HighlightsPage.vue");
const Toodledo = () => import("./components/ToodledoPage.vue");


const routes = [

    {
        path: "/home",
        name: "HomePage2",
        redirect: "/",
        meta: {
            hideNav: true
        }
    },
    {
        path: "/login",
        name: "Login",
        component: Login,

    },
    {
        path: "/toodledo",
        name: "Toodledo",
        component: Toodledo,
        meta: {
            hideNav: true
        }
    },
    {
        path: "/",
        name: "HomePage",
        //component: HomePage,
        component: () => import("@/components/HomePage.vue"),
        meta: {
            hideNav: true
        }
    },
    {
        path: "/profile",
        name: "Profile",
        // lazy-loaded
        component: Profile,
        meta: {
            breadCrumb: [
                {
                    title: 'Home',
                    to: "/home",
                    exact: true,
                },
                {
                    title: 'Profile',
                    exact: true
                }
            ]
        }
    },
    {
        path: "/starred",
        name: "Starred",
        // lazy-loaded
        component: Starred,
        meta: {
            breadCrumb: [
                {
                    title: 'Home',
                    to: "/home",
                    exact: true,
                },
                {
                    title: 'Starred',
                    exact: true
                }
            ]
        }
    },
    {
        path: "/:paramToHighlights",
        name: "Highlights",
        // lazy-loaded
        component: Highlights,
        meta: {
            breadCrumb: function (route) {
                const paramToHighlights = route.params.paramToHighlights;
                return [
                    {
                        title: 'Home',
                        to: "/home",
                        exact: true
                    },
                    {
                        title: paramToHighlights.length > 27 ? (decodeURIComponent(paramToHighlights.substring(0, 27))+"...").replaceAll("_", " "): decodeURIComponent(paramToHighlights.replaceAll("_", " ")),
                        exact: true
                    }
                ]
            }
        }
    },

];

const router = createRouter({
    history: createWebHistory(),
    base: Config.VUE_APP_API_URL,// ? Config.VUE_APP_API_URL : process.env.VUE_APP_API_URL;//"https://notes-api.familie-mattar.de/", //process.env.VUE_APP_API_URL,
    routes,
    
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
        next('/login');

    } else {
        next();
    }

});


export default router;
